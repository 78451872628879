#service-type .jobin-app-navbar .navbar-collapse li.left {
    display: block;
}

#service-type .title {
    font-weight: bold;
    margin-top: 48px;
    margin-bottom: 24px;
}

#service-type .service-list {
    position: fixed;
    top: 64px;
    z-index: 2;
    height: calc(100vh - 64px);
    width: 40%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    background-color: #f6f5f2;
    padding: 40px 48px;
}
#service-type .service-list .title {
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 24px;
    line-height: 24px;
}
#service-type .service-list ul {
    padding-left: 0;
    margin: 0;
}
#service-type .service-list ul li {
    list-style: none;
    padding: 8px 0;
}
#service-type .service-list ul li button {
    background-color: transparent;
    border: none;
    cursor: pointer;
}
#service-type .service-list ul li button > * {
    vertical-align: middle;
}
#service-type .service-list ul li img {
    width: 24px;
    height: 24px;
    margin-right: 16px;
}
#service-type .service-list ul li span {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.4;
}

.column-sm-6 {
    -webkit-column-gap: 24px;
       -moz-column-gap: 24px;
            column-gap: 24px;
}

@media (min-width: 768px) {
    .column-sm-6 {
        -webkit-column-count: 2;
           -moz-column-count: 2;
                column-count: 2;
    }
}

/*
responsive
*/
@media (max-width: 767px) {
    #nav #toggle-nav {
        display: none !important;
    }
    #service-type .jobin-app-navbar #toggle-nav {
        display: block;
    }
    #service-type .jobin-app-navbar .search-service {
        display: inline-block;
    }
    
    #service-type .jobin-app-navbar.integrations .navbar-header,
    #service-type .jobin-app-navbar.integrations #toggle-nav {
        display: block;
    }

    #service-type .title {
        font-size: 22px;
        margin-top: 32px;
        margin-bottom: 32px;
    }
    #service-type .service-item .title {
        font-size: 18px;
    }
    #service-type .service-item .excerpt .title a {
        margin-top: -10px;
    }
}