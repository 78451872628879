#nav .right .nav-menu {
    display: block;
    position: relative;
}
#nav .right .nav-menu button {
    background-color: transparent;
    padding: 0;
    border: none;
    border-radius: 0;
    color: var(--greyish-brown);
}
#nav .right .nav-menu button.dropdown-toggle {
    height: 72px;
    border-bottom: solid 2px transparent;
}

#nav .right .nav-menu a.dropdown-toggle:hover,
#nav .right .nav-menu button.dropdown-toggle:hover {
    cursor: pointer;
    border-color: var(--greyish-brown);
}
#nav .right .nav-menu a span,
#nav .right .nav-menu button span {
    display: inline-block;
    pointer-events: none;
}
#nav .right .nav-menu a span svg,
#nav .right .nav-menu a span img,
#nav .right .nav-menu button span svg {
    vertical-align: middle;
}
#nav .right .nav-menu .dropdown-menu {
    position: absolute;
    top: 74px;
    right: 0;
    width: 256px;
    height: 56px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #e8e8e8;
    padding: 8px 0;
}
#nav .right .nav-menu .dropdown-menu li {
    display: block;
    height: 100%;
    padding: 0 16px;
}
#nav .right .nav-menu .dropdown-menu li:hover {
    background-color: var(--soft-grey);
}
#nav .right .nav-menu .dropdown-menu li button {
    width: 100%;
    text-align: left;
}
#nav .right .nav-menu.collapsed .dropdown-toggle svg {
    transform: rotate(180deg);
}
