#service-category .title {
    font-weight: bold;
    margin-bottom: 12px;
}

/*
responsive
*/
@media (max-width: 767px) {
    #service-category .title {
        font-size: 22px;
        margin-top: 24px;
    }
}
