.customSelect{
    opacity: 0;
    transition: all 0.3s;
}
.customSelect.loaded{
    opacity: 1;
}
.customSelect .select {
    position: relative;
}

.customSelect .selectLabel {
    display: block;
    font-weight: bold;
    margin-bottom: 0.4rem;
}

.customSelect .selectWrapper {
    position: relative;
}

.customSelect .selectCustom {
    position: relative;
    width: 80%;
    height: 4rem;
}

.customSelect .selectCustom-trigger {
    font-size: 0.9rem;
    background-color: #fff;
    border: 1px solid #6f6f6f;
    border-radius: 0.4rem;
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding: 0.7rem 1.4rem 0.7rem 0.8rem;
    display: flex;
    align-items: center;
}


.customSelect .selectCustom-trigger::after {
    content: "▼";
    position: absolute;
    top: 0;
    line-height: 3.8rem;
    right: 0.8rem;
}
.customSelect .selectCustom.isActive .selectCustom-trigger::after {
    content: "▲";
    position: absolute;
    top: 0;
    line-height: 3.8rem;
    right: 0.8rem;
}

.customSelect .selectCustom-trigger:hover {
    border-color: var(--coral);
}

.customSelect .selectCustom-options {
    position: absolute;
    top: calc(3.8rem + 0.8rem);
    left: 0;
    width: 100%;
    border-radius: 0.4rem;
    background-color: #fff;
    z-index: 1;
    height: 0;
    /* transition: all 0.3s; */
    overflow: auto;
}

.customSelect .selectCustom.isActive .selectCustom-options {
    border: 1px solid #6f6f6f;
    box-shadow: 0 0 4px #e9e1f8;
    padding: 0.8rem 0;
    /* transition: all 0.3s; */
    min-height: 22vh;
}

.customSelect .selectCustom-option {
    position: relative;
    padding: 0.8rem;
}

.customSelect .selectCustom-option:hover {
    background-color: #f7ecff;
}

.customSelect .selectCustom-option:not(:last-of-type)::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0.8rem;
    width: calc(100% - 1.6rem);
    border-bottom: 1px solid #d3d3d3;
}



.customSelect .card {
    position: relative;
    margin: 2rem auto;
    max-width: calc(100% - 2rem);
    width: 40rem;
    background: white;
    padding: 3rem;
    box-shadow: 0.2rem 0.2rem #e9e1f8;
}