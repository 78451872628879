.bottom-controls {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 105px;
    background-color: #fff;
    z-index: 1000;
}
.controls-content {
    position: relative;
    box-shadow: inset 0 1px 0 0 #e8e8e8;
    height: 105px;
}
.controls-content .row div > div {
    line-height: 105px;
}
.controls-content img {
    vertical-align: middle;
}
.controls-content a {
    display: inline-block;
    cursor: pointer;
}
.controls-content .btn-next,
.controls-content .btn-submit {
    text-align: right;
}
.controls-content .btn-next button,
.controls-content .btn-submit button {
    cursor: pointer;
    border: none;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.2px;
    text-align: center;
    color: black;
    background-color: var(--aqua-jobin-darker);
    border-radius: 4px;
    height: 48px;
    padding: 0 24px;
}
.controls-content .btn-submit button {
    vertical-align: middle;
    background-color: var(--coral);
}
.controls-content .btn-back button {
    cursor: pointer;
    border: none;
    background-color: transparent;
    padding: 0;
}
.controls-content .btn-back button:focus {
    outline: none;
}
.controls-content button:disabled {
    opacity: 0.5;
    cursor: auto;
}
.row-terms{
    position: absolute;
    bottom: 0.75em;
    height: 0.75em;
    left: 50%;
    transform: translate(-45%, 0%);
}
.row-terms-home {
    bottom: -2.25em;
}
.includes {
    margin-top: 4em;
}
/*
responsive
*/
@media (max-width: 767px) {
    .bottom-controls {
        box-shadow: inset 0 1px 0 0 #e8e8e8;
    }
    .bottom-controls,
    .controls-content {
        height: 92px;
    }
    .bottom-controls .container > .row {
        padding-left: 8px;
        padding-right: 8px;
    }
    .controls-content {
        box-shadow: none;
    }
    .controls-content .row div > div {
        line-height: 92px;
    }
    .controls-content .btn-next button,
    .controls-content .btn-submit button {
        height: 48px;
        font-size: 15px;
    }
    .controls-content .btn-back button img {
        width: 40px;
        height: 40px;
    }
}
