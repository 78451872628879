:root {
    --bronze:#cd7f32 ;
    --new-gold:#ffc41e;
    --greyish: #b0b0b0;
    --coral: #FFE235;
    --green: #15f700;
    --warm-grey: #848484;
    --white:#FFF;
    --white-two: #e2e2e2;
    --white-four: #f0eee9;
    --light-grey: #e7e3db;
    --aqua-jobin: black;
    --aqua-jobin-darker: #3543FF;
    --bone-light: #f8f7f6;
    --greyish-brown: #444444;
    --soft-grey: #f2f2f2;
    --black-jobin: #202020;
    --kpis_background: #06141d;
    --kpis_filter: #1A3B42;
    --kpis_red: #FF3569;
    --kpis_green: #35FFC8;
    --kpis_yelow: #FAE35B;
    --offset: 187;
    --duration: 1.4s;
    --golden-shadow: 0px 1px 15px 2px var(--coral);
    --background-shadow :#000000b8;
}

/*
    special font for pelayo 
*/

@font-face {
    font-family: "tahoma";
    src: url("../assets/Fonts/Tahoma.ttf");
}
@font-face {
    font-family: "tahomaBold";
    src: url("../assets/Fonts/TahomaB.ttf");
}


/*
    special font for pelayo 
*/
#root .pelayo{
    font-family: "tahoma";
}


/*
semantic elements
*/
body {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: var(--greyish-brown);
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

p {
    font-size: 14px;
    color: var(--greyish-brown);
}
p b {
    font-weight: 600;
}
p + p {
    margin-top: 0;
}

h1 {
    font-size: 48px;
    font-weight: 600;
    color: var(--greyish-brown);
    line-height: 1.17;
}
h2 {
    font-size: 32px;
    font-weight: 600;
    color: var(--greyish-brown);
}
h3 {
    font-size: 28px;
    font-weight: normal;
    color: var(--greyish-brown);
}
h3 b {
    font-weight: 600;
}
h4 {
    font-size: 24px;
    font-weight: 600;
    color: var(--greyish-brown);
}
h5 {
    font-size: 18px;
    font-weight: 400;
}

hr {
    height: 1px;
    border: none;
    margin-top: 32px;
    margin-bottom: 32px;
    box-shadow: inset 0 -1px 0 0 var(--white-two);
}

/*
utility classes
*/
.position-relative {
    position: relative;
}

.no-scroll {
    overflow: hidden;
}

.hidden {
    display: none !important;
}
.visible {
    display: initial !important;
}
.native-hidden {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: absolute;
    left: -999px;
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.noscroll {
    position: relative;
    height: 100%;
    overflow: hidden;
}

button.nostyle {
    background-color: transparent;
    border: none;
    padding: 0;
}

.img-container {
    line-height: 0;
}
.img-responsive {
    max-width: 100%;
    margin: auto;
}

.no-padding-left-right {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.no-margin-top-bottom {
    margin-top: 0;
    margin-bottom: 0;
}
.margin-top-0 {
    margin-top: 0;
}
.margin-top-32px {
    margin-top: 32px;
}
.margin-top-48px {
    margin-top: 48px;
}
.margin-top-64px {
    margin-top: 64px;
}
.margin-top-72px {
    margin-top: 72px;
}
.margin-bottom-16px {
    margin-bottom: 16px;
}

.padding-top-0 {
    padding-top: 0;
}
.padding-bottom-0 {
    padding-bottom: 0 !important;
}
.padding-top-bottom-128px {
    padding-top: 128px !important;
    padding-bottom: 128px !important;
}

.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}

.line-height-14 {
    line-height: 1.4;
}
.line-height-154 {
    line-height: 1.54;
}
.line-height-22px {
    line-height: 22px;
}
.line-height-24px {
    line-height: 24px;
}
.line-height-26px {
    line-height: 26px;
}
.line-height-28px {
    line-height: 28px;
}

/*helper DOM element in conjunction with images and text*/
.vertical-helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.vertical-center {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.vertical-align-middle > * {
    display: inline-block;
    vertical-align: middle;
}

.cursor-pointer {
    cursor: pointer;
}

.container-xs {
    margin-right: auto;
    margin-left: auto;
    padding-left: 16px;
    padding-right: 16px;
}
.container-md {
    margin-right: auto;
    margin-left: auto;
}

.st {
    text-decoration: line-through;
}

.bg-medium-turquoise {
    background-color: var(--medium-turquoise);
}
.bg-aqua-jobin {
    background-color: var(--aqua-jobin);
}
.bg-aqua-jobin-darker {
    background-color: var(--aqua-jobin-darker);
}
.bg-mosque {
    background-color: var(--mosque);
}
.bg-soft-grey {
    background-color: var(--soft-grey);
}
.bg-footer-jobin {
    background-color: var(--black-jobin);
}
.bg-black-jobin {
    background-color: var(--black-jobin);
}
.bg-soft-grey {
    background-color: var(--soft-grey);
}
.bg-coral {
    background-color: var(--coral);
}

.color-coral {
    color: var(--coral);
}
.color-white {
    color: #fff;
}
.color-greyish {
    color: var(--greyish);
}

.color-white h1,
.color-white h2,
.color-white h3,
.color-white h4,
.color-white p,
.color-white li {
    color: #fff;
}

.coral-fill {
    fill: #fa504c;
}
.white-fill {
    fill: #fff;
}
.black-jobin-fill {
    fill: var(--black-jobin);
}

.font-size-36px {
    font-size: 36px;
}
.font-size-80px {
    font-size: 80px;
}

.border-radius-4px {
    border-radius: 4px;
}

.grecaptcha-badge {
    display: none !important;
}

/*
grid modifitacions
*/
.row {
    margin-left: -16px;
    margin-right: -16px;
}
.layout {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    max-width: 1600px;
    padding: 0 30px;
    width: 100%;
}

.col,
.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
    padding-left: 16px;
    padding-right: 16px;
}

/*
inputs
*/
.jobin-app-input-groupv3 input {
    border: none;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding: 20px 24px;
}
.jobin-app-input-groupv3 input:focus {
    outline: none;
}

/*
input search
*/
.input-search-container {
    position: relative;
}
.input-search-container button {
    padding: 0;
    background-color: transparent;
    border: none;
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translate(0, -50%);
}
.input-search-container input {
    width: 100%;
    height: 48px;
    border: none;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    font-size: 16px;
    line-height: 1.5;
    padding: 12px 12px 12px 48px;
    background-color: var(--soft-grey);
    -webkit-appearance: none;
    appearance: none;
    border: 1px solid transparent;
}
.input-search-container input:focus {
    outline: none;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05);
    border-color:#e8e8e8;
}
.input-search-container input:placeholder,
textarea:placeholder {
    color: var(--greyish);
}
.input-search-container input:focus ~ button svg g.color-fill path {
    fill: var(--greyish-brown);
}

.input-search-container input::-webkit-input-placeholder {
    color: var(--greyish);
}
.input-search-container input:-ms-input-placeholder {
    color: var(--greyish);
}
.input-search-container input::placeholder {
    color: var(--greyish);
}

/*
buttons
*/
.jobin-landing-cta {
    border: none;
    width: 100%;
    max-height: 56px;
    padding: 17px 24px;
    background-color: var(--coral);
    border-radius: 4px;
    font-size: 17px;
    font-weight: 600;
    color: #fff;
    border: solid 2px rgba(32, 32, 32, 0.2);
}
.jobin-landing-cta:hover {
    cursor: pointer;
    background-color: var(--coral-dark);
}

.jobin-app-cta {
    padding: 12px 24px;
    display: inline-block;
    color: var(--greyish-brown);
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    border: 2px solid var(--greyish-brown);
    border-radius: 4px;
}

/*
lists with radio buttons
*/
.subvert-list {
    list-style: none;
    padding-left: 32px;
    padding-right: 32px;
    margin-bottom: 0;
    text-align: left;
    margin-top: 40px;
    padding: 0;
}
.subvert-list li + li {
    margin-top: 24px;
}
.subvert-list label {
    position: relative;
    display: block;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: normal;
    cursor: pointer;
}
.subvert-list label > * {
    vertical-align: middle;
}
.subvert-list label svg {
    position: absolute;
    top: 4px;
}
.subvert-list label p {
    font-size: 16px;
    display: inline-block;
    margin: 0;
    margin-left: 32px;
    text-decoration: none;
    color: var(--greyish-brown);
}

/*
navbar
*/
.jobin-app-navbar {
    position: fixed;
    top: 0;
    width: 100%;
    height: 72px;
    z-index: 100;
    padding: 0 24px;
    background-color: #fff;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
}
.jobin-app-navbar .navbar-header {
    float: left;
}
.jobin-app-navbar .navbar-brand:hover {
    text-decoration: none;
}
.jobin-app-navbar .navbar-brand img,
.jobin-app-navbar .navbar-brand svg {
    height: 40px;
    vertical-align: middle;
}
.jobin-app-navbar .navbar-header,
.jobin-app-navbar .navbar-collapse {
    line-height: 72px;
}

.jobin-app-navbar .navbar-collapse {
    margin: 0;
    padding-left: 0;
    list-style: none;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    position: relative;
    opacity: 1;
}
.jobin-app-navbar .navbar-collapse li.left {
    display: none;
    float: left;
    margin-left: 32px;
}
.jobin-app-navbar .navbar-collapse li.right {
    float: right;
}
.jobin-app-navbar .navbar-collapse > li ul {
    padding-left: 0;
}
.jobin-app-navbar .navbar-collapse li {
    margin-top: 0;
    display: inline-block;
}
.jobin-app-navbar .navbar-collapse li + li {
    padding-left: 32px;
}
.jobin-app-navbar .navbar-collapse a {
    text-decoration: none;
}
.jobin-app-navbar .navbar-collapse a,
.jobin-app-navbar .navbar-collapse button {
    font-size: 14px;
    font-weight: 600;
    color: var(--greyish-brown);
    display: block;
    height: 100%;
}
.jobin-app-navbar .navbar-collapse button {
    border: none;
    background-color: var(--coral);
    color: #fff;
    border-radius: 4px;
    padding: 16px 20px;
}
.jobin-app-navbar .navbar-collapse button:hover {
    cursor: pointer;
    background-color: var(--coral-dark);
}
.jobin-app-navbar .navbar-collapse button:active {
    background-color: var(--coral);
}
.jobin-app-navbar .navbar-collapse button:focus {
    outline: none;
    border: solid 1px var(--coral-darker);
}
.jobin-app-navbar .navbar-collapse a:hover {
    height: 72px;
    color: var(--greyish-brown);
    border-bottom: solid 2px var(--greyish-brown);
}
.jobin-app-navbar .navbar-collapse .active a {
    height: 72px;
    color: var(--coral);
    border-bottom: solid 2px var(--coral);
}
.jobin-app-navbar .navbar-collapse #upload-application a {
    display: unset;
    background-color: var(--coral);
    color: #fff;
    height: 40px;
    border-radius: 4px;
    padding: 10px 16px;
}
.jobin-app-navbar .navbar-collapse #upload-application a:hover {
    text-decoration: none;
    background-color: var(--coral-dark);
}
.jobin-app-navbar .navbar-collapse #jobin-business-login a {
    display: unset;
    padding: 10px 16px;
    border-radius: 4px;
    background-color: var(--jobin-business);
    color: #fff;
    font-weight: 600;
}
.jobin-app-navbar .navbar-collapse #jobin-business-login a:hover {
    background-color: var(--jobin-business-dark);
    text-decoration: none;
}

/*navbar toggle*/
.jobin-app-navbar #toggle-nav {
    display: none;
    position: absolute;
    height: 56px;
    width: 72px;
    top: 0;
    left: 0;
    text-align: right;
    padding: 0;
    outline: none;
    border-width: 0;
    background-color: transparent;
    cursor: pointer;
}
.jobin-app-navbar #toggle-nav img,
.jobin-app-navbar #toggle-nav svg {
    width: 20px;
}
.jobin-app-navbar.collapsed #toggle-nav {
    position: fixed;
    z-index: 200;
}
.jobin-app-navbar.collapsed #toggle-nav img,
.jobin-app-navbar.collapsed #toggle-nav svg {
    transform: rotate(180deg);
}
.jobin-app-navbar.collapsed {
    position: initial !important;
}
.jobin-app-navbar.collapsed .navbar-header {
    position: fixed;
    z-index: 200;
}
.jobin-app-navbar.collapsed .navbar-collapse {
    display: block;
    position: fixed;
    background-color: #fff;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    overflow: scroll;
}
.jobin-app-navbar.collapsed .navbar-collapse > li {
    font-size: 18px;
    margin-left: 24px;
}
.jobin-app-navbar.collapsed .navbar-collapse > li:first-child {
    margin-top: 100px;
}
.jobin-app-navbar.collapsed .navbar-collapse li {
    display: block;
}
.jobin-app-navbar.collapsed .navbar-collapse li + li {
    padding-left: 0;
}
.jobin-app-navbar.collapsed .navbar-collapse li.left,
.jobin-app-navbar.collapsed .navbar-collapse li.right {
    float: initial;
}

.jobin-app-navbar.collapsed .navbar-collapse .nav-item {
    position: relative;
    padding-left: 40px;
}
.jobin-app-navbar.collapsed .navbar-collapse .nav-item svg {
    position: absolute;
    top: 16px;
    left: 0;
}
.jobin-app-navbar.collapsed .navbar-collapse li.right {
    height: 56px;
}
.jobin-app-navbar.collapsed .navbar-collapse li.right .nav-menu {
    position: relative;
    height: 56px;
    padding-left: 40px;
}
.jobin-app-navbar.collapsed .navbar-collapse li.right .nav-menu > svg {
    position: absolute;
    top: 16px;
    left: 0;
}
.jobin-app-navbar.collapsed .navbar-collapse a:hover {
    height: initial;
    color: var(--coral);
    border: none;
}
.jobin-app-navbar.collapsed .navbar-collapse a:hover ~ svg g path {
    fill: var(--coral);
}
#nav.collapsed .right .nav-menu a.dropdown-toggle:hover, 
#nav.collapsed .right .nav-menu button.dropdown-toggle:hover {
    border: none;
}
#nav.collapsed .right .nav-menu .dropdown-menu {
    height: 22px;
    border: none;
    padding: 0;
    box-shadow: none;
    top: 48px;
    left: 40px;
}
#nav.collapsed .right .nav-menu .dropdown-menu li {
    padding: 0;
}
#nav.collapsed .right .nav-menu .dropdown-menu li button {
    font-size: 12px;
    font-weight: normal;
}

/*
footer
*/
.jobin-landing-footer {
    padding: 72px 0;
}
.jobin-landing-footer p.title {
    font-size: 16px;
    color: #fff;
    margin-bottom: 16px;
}
.jobin-landing-footer ul {
    margin-bottom: 0;
    padding-left: 0;
}
.jobin-landing-footer ul li {
    list-style: none;
    line-height: 22px;
}
.jobin-landing-footer a {
    color: var(--warm-grey);
    text-decoration: none;
}
.jobin-landing-footer a:hover {
    color: #fff;
}

/*section*/
.section {
    max-height: calc(100vh - 105px);
    overflow: auto;
}

/*
responsive
*/
@media (max-width: 767px) {
    p {
        font-size: 15px;
    }
    h1 {
        font-size: 32px;
    }
    h2 {
        font-size: 24px;
    }
    h3 {
        font-size: 20px;
    }
    h4 {
        font-size: 18px;
    }
    h5 {
        font-size: 15px;
    }
    /*gutter*/
    .margin-top-72px {
        margin-top: 64px;
        margin-bottom: 16px;
    }
    .margin-top-72px > .container {
        padding-left: 24px;
        padding-right: 24px;
    }
    .jobin-app-navbar,
    .layout {
        padding: 0 24px 0 16px;
    }

    /*navbar*/
    .jobin-app-navbar {
        height: 56px;
    }
    .jobin-app-navbar .navbar-header,
    .jobin-app-navbar .navbar-collapse {
        line-height: 56px;
    }
    .jobin-app-navbar .right > ul {
        display: inline-block;
        padding-left: 16px;
    } 
    #nav .right .nav-menu button.dropdown-toggle {
        border: none;
        height: 56px;
    }
    .jobin-app-navbar .navbar-header ul.logos {
        padding: 10px 0;
        height: 56px;
    }
    .jobin-app-navbar .navbar-header .logos img {
        height: 100%;
        vertical-align: unset;
    }
    .jobin-app-navbar.collapsed.color-white .navbar-brand svg > g {
        fill: var(--coral);
    }
    .jobin-app-navbar.collapsed.color-white .navbar-collapse a {
        color: var(--greyish-brown);
    }

    /*hide logos when integration appears*/
    .jobin-app-navbar.integrations .navbar-header,
    .jobin-app-navbar.integrations #toggle-nav {
        display: none;
    }

    .jobin-app-navbar.integrations.bauhaus .navbar-header {
        display: block !important;
    }
    /*navbar collapse*/
    /*.navbar-collapse {*/
    /*    display: none;*/
    /*}*/
    /*navbar*/
    .jobin-app-navbar {
        height: 56px;
    }
    .jobin-app-navbar .navbar-header .navbar-brand {
        display: block;
        width: 32px;
    }
    .jobin-app-navbar .navbar-header,
    .jobin-app-navbar .navbar-collapse {
        line-height: 56px;
    }
    .jobin-app-navbar .navbar-brand-text {
        display: none;
    }
    .jobin-app-navbar {
        padding: 0 24px 0 16px;
    }
    .jobin-app-navbar .navbar-brand img,
    .jobin-app-navbar .navbar-brand svg {
        height: 40px;
    }
    .jobin-app-navbar.collapsed.color-white .navbar-brand svg > g {
        fill: var(--coral);
    }
    .jobin-app-navbar.collapsed.color-white .navbar-collapse a {
        color: var(--greyish-brown);
    }
    .jobin-app-navbar .search-service {
        display: none;
        margin-top: 8px;
        width: calc(100% - 56px);
        margin-left: 24px;
    }
    #service-type .jobin-app-navbar .search-service {
        margin-bottom: 0;
    }
    .jobin-app-navbar .search-service .input-search-container {
        position: relative;
    }
    .jobin-app-navbar .search-service .input-search-container input {
        font-size: 14px;
        padding-left: 40px;
        padding-top: 8px;
        padding-bottom: 8px;
        width: 100%;
        height: 40px;
        border-radius: 4px;
        -webkit-appearance: none;
        appearance: none;
    }
    .jobin-app-navbar .search-service .input-search-container button {
        transform: translate(0, 0);
        top: 10px;
    }
    .jobin-app-navbar .search-service .input-search-container .search-icon {
        width: 16px;
    }

    /*footer*/
    .jobin-app-footer {
        padding: 32px 0;
        text-align: center;
    }
    .jobin-app-footer .col-xs-12 {
        margin-top: 32px;
    }
    .jobin-app-footer #footer-links {
        margin-top: 24px;
        margin-bottom: 24px;
    }
    .jobin-app-footer #footer-links li {
        display: block;
    }
    .jobin-app-footer #footer-links li + li:before {
        content: none;
    }

    .section {
        max-height: calc(100vh - 92px);
    }

    .subvert-list {
        margin-bottom: 32px;
    }
}
@media screen and (min-width: 768px) {
}
@media screen and (min-width: 1024px) {
}
@media (min-width: 1200px) {
    .container-md {
        width: 75%;
    }
}
