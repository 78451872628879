#service-urgency .title {
    margin-top: 48px;
    font-weight: bold;
    margin-bottom: 12px;
}

#service-urgency .urgency-list {
    margin-top: 40px;
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    text-align: left;
}
#service-urgency .urgency-list li + li {
    margin-top: 24px;
}
#service-urgency .urgency-list label {
    position: relative;
    display: block;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: normal;
    cursor: pointer;
}
#service-urgency .urgency-list label > * {
    vertical-align: middle;
}
#service-urgency .urgency-list label svg {
    position: absolute;
    top: 4px;
}
#service-urgency .urgency-list label p {
    font-size: 16px;
    display: inline-block;
    margin: 0;
    margin-left: 32px;
    text-decoration: none;
    color: var(--greyish-brown);
}
#service-urgency .urgency-list label sub {
    display: block;
    margin-left: 37px;
    margin-top: 8px;
}

/*
responsive
*/
@media (max-width: 767px) {
    #service-urgency .title {
        font-size: 22px;
        margin-top: 24px;
    }
    #service-urgency .urgency-list label sub {
        display: none;
    }
}
