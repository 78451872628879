#service-price .title {
    margin-top: 48px;
    font-weight: bold;
    margin-bottom: 12px;
}

#service-price .add-extras{
    display: flex;
    flex: 1;
    flex-direction: column;
}

#service-price .add-extras .button-view{
    display: flex;
    margin-top: 8px;
}
.plusButton{
    text-align: center;
    background: var(--coral);
    border: none;
    padding: 5px 10px;
    margin: 0 5px;
    font-size: 20px;
    border-radius: 5px;
    width: 35px;
    font-weight: 800;
}
.plusButton:disabled{
    text-align: center;
    background: var(--color-grey-midium);
    border: none;
    padding: 5px 10px;
    margin: 0 5px;
    font-size: 20px;
    border-radius: 5px;
    width: 35px;
    font-weight: 800;
}
.multiplyElement{
    font-size: 25px;
    font-weight: 700;
    color: var(--greyish-brown);
    margin: 0 10px;
    line-height: 1.17;
    opacity: 1;
    text-decoration: none;
}
@media (max-width: 767px) {

    .multiplierContainer{
        display: flex;
        justify-content: center;
        align-items: center;
    }    
}
